import { useBikesQuery } from "../generated/graphql";

export const useBikesFromCity = (cityId?: number | null) => {
  const res = useBikesQuery({
    variables: {
      cityId,
    },
    onCompleted: () => {
      res.refetch();
    },
  });

  if (res.data && res.data.bikes.length > 0) {
    const { bikes } = res.data;
    return {
      ...res,
      data: bikes[0].bikes,
    };
  } else {
    return {
      ...res,
      data: undefined,
    };
  }
};
