import { ApiResponse } from "../types/api";

const fetcher = async <T extends unknown>(
  input: RequestInfo,
  init?: RequestInit
): Promise<ApiResponse<T>> => {
  const res = await fetch(input, init);
  const json = await res.json();

  const apiResponse: ApiResponse<T> = {
    code: res.status,
    status: res.statusText,
    data: json,
    errors: json?.errors || json?.error,
  };

  if (json?.errors || json?.error) {
    throw new Error(json?.description || json?.error || json?.errors);
  } else {
    return apiResponse;
  }
};

export const swrFetcher = async <T extends unknown>(
  input: RequestInfo,
  init?: RequestInit
): Promise<T> => {
  const response = await fetcher<T>(input, init);
  return response.data as T;
};

export const swrFetcherFull = async <T extends unknown>(
  input: RequestInfo,
  init?: RequestInit
): Promise<T> => {
  const response = await fetcher<T>(input, init);
  return response as T;
};

export default fetcher;
