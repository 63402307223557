import { JZ } from "@buzzbike/ui/src/DesignSystem";
import {
  Box,
  CloseButton,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  Spinner,
  Stack,
  Text,
  useMergeRefs,
} from "@chakra-ui/react";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";
import { debounce, isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { ReactNode } from "react-markdown";
import useSWR from "swr";

import { getUdprn, swrGetPostcodeAutocomplete } from "utils/api";
import endPoints from "utils/endpoints";

export type Address = {
  postcode?: string;
  address_line_one?: string;
  address_line_two?: string;
  city?: string;
  flat?: string;
  house_number?: string;
  house_name?: string;
  street?: string;
  street_2?: string;
  district?: string;
  county?: string;
};

type PostcodeAutocompleteProps = InputProps & {
  onPostcodeSelected?: (address: Address | undefined) => void;
};
const PostcodeAutocomplete = React.forwardRef<
  HTMLInputElement,
  PostcodeAutocompleteProps
>(({ onPostcodeSelected }: PostcodeAutocompleteProps, ref) => {
  const internalRef = React.createRef<HTMLInputElement>();
  const refs = useMergeRefs(internalRef, ref);

  const [query, setQuery] = useState<string>("");
  const [queryString, setQueryString] = useState<string>("");
  const [udprn, setUdprn] = useState<string>();

  const { data: postCodeOptions = [], isValidating } = useSWR(
    endPoints.postcodeAutocomplete(query),
    () => swrGetPostcodeAutocomplete(query),
    { revalidateOnFocus: false }
  );
  const { data: udprnData } = useSWR(
    endPoints.udprn(udprn),
    () => (udprn ? getUdprn(udprn) : undefined),
    { revalidateOnFocus: false }
  );

  const onSelectOption = ({ optionValue }: { optionValue: string }) => {
    setUdprn(optionValue);
    setQuery("");
  };

  useEffect(() => {
    if (udprnData) {
      onPostcodeSelected?.(udprnData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [udprnData]);

  useEffect(() => {
    const wrapper = internalRef?.current?.closest("div");
    if (wrapper) wrapper.tabIndex = -1;
  }, [internalRef]);

  const debounce_fun = debounce((string) => {
    setQuery(string);
  }, 500);

  const getEmptyState = () => <></>;

  return (
    <AutoComplete
      emptyState={getEmptyState()}
      onSelectOption={onSelectOption}
      closeOnBlur={true}
    >
      <InputGroup className="auto-complete-input-group">
        <AutoCompleteInput
          {...JZ["Title/20 Med"]}
          color={JZ.BuzzBlack}
          placeholder="Start typing"
          value={queryString}
          onChange={(e) => {
            setQueryString(e.target.value);
            debounce_fun(e.target.value);
          }}
          autoComplete="new-password"
          ref={refs}
          variant="flushed"
          _invalid={{
            borderColor: JZ.Negative,
            boxShadow: `0px 1px 0px 0px ${JZ.Negative}`,
          }}
          _focus={{
            borderColor: JZ.Pink,
            boxShadow: `0px 1px 0px 0px ${JZ.Pink}`,
          }}
        />
      </InputGroup>
      <AutoCompleteList backgroundColor="white" mt="0" w="full">
        <PopoverArrow />
        <PopoverHeader></PopoverHeader>
        <PopoverCloseButton />
        {isValidating ? (
          <Box alignSelf="center">
            <Spinner />
          </Box>
        ) : !isEmpty(postCodeOptions) ? (
          <Stack justifyItems={"center"} spacing={2}>
            {postCodeOptions.map((option, oid) => (
              <AutoCompleteItem
                fixed={true}
                _hover={{
                  backgroundColor: JZ.Pink,
                  color: JZ.White,
                }}
                key={`option-${oid}`}
                value={option.udprn.toString()}
                minH="10"
                {...JZ["Title/16 Med"]}
                color={JZ.BuzzBlack}
                mx={0}
              >
                {option.suggestion}
              </AutoCompleteItem>
            ))}
          </Stack>
        ) : (
          <Box alignSelf="center">No options</Box>
        )}
      </AutoCompleteList>
    </AutoComplete>
  );
});

type FindAddressProps = {
  label?: string;
  errorMessage?: string;
  helperText?: string;
  address?: string;
  addressManuallyButton?: ReactNode;
  additionComponent?: ReactNode;
  onPostcodeSelected?: (address: Address | undefined) => void;
};
const FindAddress = React.forwardRef<HTMLInputElement, FindAddressProps>(
  (props: FindAddressProps, ref) => {
    const {
      label,
      errorMessage,
      address,
      helperText,
      addressManuallyButton,
      additionComponent,
      onPostcodeSelected,
    } = props;
    const removeAddress = () => {
      if (onPostcodeSelected) {
        onPostcodeSelected(undefined);
      }
    };

    return (
      <FormControl isInvalid={!!errorMessage}>
        <HStack alignItems="baseline" justifyContent="space-between">
          <FormLabel {...JZ["Caption/14 Med"]} color={JZ.BuzzBlack}>
            {label || "Find Address"}
          </FormLabel>
          <Box display={["none", "block"]}>{addressManuallyButton}</Box>
        </HStack>
        {address ? (
          <InputGroup>
            <Input
              {...JZ["Title/20 Med"]}
              color={JZ.BuzzBlack}
              variant={"flushed"}
              value={address}
              onChange={() => {}}
              focusBorderColor={JZ.Pink}
              errorBorderColor={JZ.Negative}
            />
            <InputRightElement pl={2}>
              <CloseButton
                size={"lg"}
                onClick={removeAddress}
                color={JZ.BuzzBlack}
              ></CloseButton>
            </InputRightElement>
          </InputGroup>
        ) : (
          <PostcodeAutocomplete
            onPostcodeSelected={onPostcodeSelected}
            ref={ref}
          />
        )}
        <Box display={["block", "none"]} mt={2}>
          {addressManuallyButton}
        </Box>
        <FormHelperText {...JZ["Caption/14 Med"]} color={JZ["Darkest Gray"]}>
          {helperText}
        </FormHelperText>
        <FormErrorMessage color={JZ.Negative}>
          <Text>
            {errorMessage} {additionComponent}
          </Text>
        </FormErrorMessage>
      </FormControl>
    );
  }
);
export default FindAddress;
