import { JZ } from "@buzzbike/ui/src/DesignSystem";
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { FieldError, FieldValues, useController } from "react-hook-form";
import { Props as InputMaskProps } from "react-input-mask";
import { ReactNode } from "react-markdown";

import InputMask from "components/InputMask";
import { UseControllerPropsNarrowing } from "utils/formTyping";

type FormInputProps<TFieldValues extends FieldValues = FieldValues> = Omit<
  InputProps,
  "name" | "defaultValue" | "value"
> & {
  label?: string;
  rightLabel?: React.ReactNode;
  helperText?: string;
  customError?: ReactNode;
  additionComponent?: ReactNode;
  inputRightElement?: ReactNode;
  inputMaskProps?: Pick<
    InputMaskProps,
    | "mask"
    | "maskPlaceholder"
    | "alwaysShowMask"
    | "inputRef"
    | "beforeMaskedStateChange"
  >;
} & UseControllerPropsNarrowing<string | undefined, TFieldValues>;

const FormInput = <TFieldValues extends FieldValues = FieldValues>(
  props: FormInputProps<TFieldValues>
) => {
  const {
    name,
    label,
    rightLabel,
    helperText,
    inputMaskProps,
    control,
    defaultValue,
    customError,
    inputRightElement,
    additionComponent,
    onBlur,
    onChange,
    ...restProps
  } = props;

  // TODO TS not able to realise a string is valid here (4.2.4)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const defaultWithEmpty = (defaultValue ?? "") as any;

  const {
    field: {
      value,
      onBlur: onFieldBlur,
      onChange: onFieldChange,
      ...restFieldProps
    },
    fieldState: { error, invalid },
  } = useController({
    name,
    control,
    defaultValue: defaultWithEmpty,
  });

  return (
    <FormControl isInvalid={invalid || !!customError}>
      <HStack align="center" justify="space-between">
        <FormLabel
          htmlFor={name}
          {...JZ["Caption/14 Med"]}
          color={JZ.BuzzBlack}
        >
          {label}
        </FormLabel>
        {rightLabel}
      </HStack>
      <InputGroup>
        <Input
          as={InputMask}
          mask=""
          id={name}
          value={value || ""}
          onBlur={(e) => {
            onFieldBlur();
            onBlur?.(e);
          }}
          onChange={(e) => {
            onFieldChange(e);
            onChange?.(e);
          }}
          variant="flushed"
          color={JZ.BuzzBlack}
          {...JZ["Title/20 Med"]}
          focusBorderColor={JZ.Pink}
          errorBorderColor={JZ.Negative}
          {...restFieldProps}
          {...inputMaskProps}
          {...restProps}
        />
        {inputRightElement && (
          <InputRightElement>{inputRightElement}</InputRightElement>
        )}
      </InputGroup>
      <FormErrorMessage color={JZ.Negative}>
        <Text>
          {error && (error as FieldError).message} {additionComponent}
          {!error && customError}
        </Text>
      </FormErrorMessage>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};
export default FormInput;
