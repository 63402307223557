import { useCitiesQuery } from "../generated/graphql";

export const useCities = () => {
  const res = useCitiesQuery({
    onCompleted: () => {
      res.refetch();
    },
  });

  return res;
};
