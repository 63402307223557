import { JZ } from "@buzzbike/ui/src/DesignSystem";
import {
  chakra,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  CityFragment,
  useJoinTheGeoggraphicWaitlistMutation,
} from "generated/graphql";
import { useBikesFromCity } from "hooks/useBikesFromCity";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";

import { Option } from "components/FormRadio";
import TickIcon from "components/icon/illustrations/TickIcon";
import BikeOptionCard from "components/newUI/BikeOptionCard";
import FindAddress from "components/newUI/FindAddress";
import FormCheckbox from "components/newUI/FormCheckbox";
import FormInput from "components/newUI/FormInput";
import PrimaryButton from "components/PrimaryButton";
import RadioCardGroup from "components/RadioCardGroup";
import { getValidPostcode } from "utils/validateHelpers";

import { CloseIcon } from "../icon/CloseIcon";

const schema = yup.object({
  email: yup
    .string()
    .email("Please provide a valid email")
    .required("This field is required"),
  postcode: yup.string().required("This field is required"),
  marketingOptIn: yup.boolean(),
});

type FormValues = yup.Asserts<typeof schema>;

function GeographicWaitlistModal({
  defaultPostcode,
  isOpen,
  cities,
  title,
  onClose,
  onJoinNow,
}: {
  defaultPostcode?: string;
  isOpen: boolean;
  cities?: CityFragment[];
  title?: string;
  onClose: () => void;
  onJoinNow: (cityId: number) => Promise<void>;
}) {
  const [errorMeg, setErrorMeg] = useState<string>();
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);
  const [cityId, setCityId] = useState<number>();
  const [joinTheGeoggraphicWaitlist] = useJoinTheGeoggraphicWaitlistMutation();
  const { data: bikeData } = useBikesFromCity();
  const [bike, setBike] =
    useState<
      | {
          bikeId: number;
          bikeName: string;
        }
      | undefined
    >();

  useEffect(() => {
    if (!bike && bikeData && bikeData.length > 0) {
      const defaultBike = bikeData[0];

      if (defaultBike) {
        setBike({
          bikeId: defaultBike.bikeId,
          bikeName: defaultBike.name,
        });
      }
    }
  }, [bikeData, bike]);

  const renderBikes = useCallback(() => {
    if (bikeData) {
      return bikeData
        .map((bike) => {
          if (bike) {
            const option: Option = {
              value: bike.bikeId,
              label: (
                <BikeOptionCard
                  name={bike.name}
                  description={bike.shortDescription.replace("-", "-\u2060")}
                  image={bike.images?.[0].imageUrl}
                />
              ),
            };
            return option;
          }
        })
        .filter((item): item is Option => !!item);
    }
    return [];
  }, [bikeData]);

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
  });
  const {
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting, errors },
    trigger,
  } = methods;

  const postcode = watch("postcode");

  const onSubmit = useCallback(
    async (values: FormValues) => {
      if (!bike) return;
      const { data } = await joinTheGeoggraphicWaitlist({
        variables: {
          input: {
            email: values.email,
            postcode: values.postcode,
            bikeModelId: bike.bikeId,
            wantsMarketingEmails: values.marketingOptIn,
          },
        },
      });
      if (data) {
        if (data.joinTheGeoggraphicWaitlist.__typename === "APIResult") {
          setIsSuccessful(true);
          setErrorMeg(undefined);
        } else {
          setErrorMeg(data.joinTheGeoggraphicWaitlist.message);
        }
      }
    },
    [bike, joinTheGeoggraphicWaitlist]
  );

  useEffect(() => {
    setValue("postcode", defaultPostcode);
  }, [defaultPostcode, setValue]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"3xl"}
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton
          as={CloseIcon}
          color={JZ["Grey text and icon"]}
          boxSize={6}
          m={2}
          cursor={"pointer"}
        />
        <ModalBody>
          {isSuccessful ? (
            <Stack
              px={{ base: 0, md: 10 }}
              py={{ base: 4, md: 8 }}
              spacing={8}
              alignItems="center"
            >
              <TickIcon color={JZ.Positive} />
              <Stack spacing={6} color={JZ.BuzzBlack} alignItems="center">
                <Text {...JZ["Web/H3 Class A"]}>
                  Thank You for Joining Our Waitlist!
                </Text>
                <Text {...JZ["Web/Caption/18 Med"]} textAlign="center">
                  We appreciate your interest! We&apos;ll keep you updated on
                  our service availability in your area.
                </Text>
              </Stack>
            </Stack>
          ) : (
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack
                  px={{ base: 0, md: 4 }}
                  py={{ base: 4, md: 8 }}
                  spacing={10}
                >
                  <Stack spacing={6} color={JZ.BuzzBlack}>
                    <Text {...JZ["Web/H3 Class A"]}>
                      {title || "Eager for Our Launch in Your Area?"}
                    </Text>
                    <Text {...JZ["Web/Caption/18 Med"]}>
                      Get notified when we launch in your area.
                    </Text>
                  </Stack>
                  <Stack spacing="6">
                    <Stack spacing="6" maxW="lg">
                      <FindAddress
                        label="Postcode"
                        address={postcode}
                        onPostcodeSelected={(address) => {
                          setValue("postcode", address?.postcode);
                          trigger("postcode");
                          setCityId(
                            getValidPostcode(address?.postcode, cities)?.id
                          );
                        }}
                        errorMessage={errors["postcode"]?.message}
                      />
                      {!cityId && (
                        <FormInput
                          name="email"
                          label="Your Email"
                          type="email"
                          autoComplete="username"
                          autoCapitalize="off"
                          autoCorrect="off"
                          placeholder="sample@buzzbike.cc"
                        />
                      )}
                    </Stack>
                    {!cityId ? (
                      <>
                        <Stack spacing={4}>
                          <Text
                            {...JZ["Web/Title/24 Semi"]}
                            color={JZ.BuzzBlack}
                          >
                            Which bike do you want to ride?
                          </Text>
                          <RadioCardGroup
                            value={bike?.bikeId}
                            onValueChange={(value) => {
                              setBike({
                                bikeId: Number.parseInt(value),
                                bikeName:
                                  bikeData?.find(
                                    (i) => i?.bikeId === Number.parseInt(value)
                                  )?.name || "Buzzbike",
                              });
                            }}
                            name="bikeId"
                            options={renderBikes()}
                            w="100%"
                            spacing={4}
                            direction={[
                              "column",
                              "column",
                              "column",
                              "row",
                              "row",
                            ]}
                            justify={["center", "space-between"]}
                          />
                        </Stack>
                        <FormCheckbox<FormValues> name="marketingOptIn">
                          <Text {...JZ["Web/Caption/16 Med"]}>
                            Get offers, free events, and updates by email
                            (unsubscribe at any time)
                          </Text>
                        </FormCheckbox>
                      </>
                    ) : (
                      <Stack color={JZ.BuzzBlack}>
                        <Text {...JZ["Web/Title/24 Semi"]}>Great News!</Text>
                        <Text {...JZ["Web/Caption/18 Med"]}>
                          Your postcode is{" "}
                          <chakra.span {...JZ["Body/18 Semi"]}>
                            within our current delivery zone.
                          </chakra.span>{" "}
                          You can go ahead and join now to start enjoying our
                          services right away!
                        </Text>
                      </Stack>
                    )}
                  </Stack>
                  <Stack>
                    {cityId ? (
                      <PrimaryButton
                        px={24}
                        w="fit-content"
                        onClick={() => onJoinNow(cityId)}
                      >
                        Join Now
                      </PrimaryButton>
                    ) : (
                      <>
                        <PrimaryButton
                          px={24}
                          type="submit"
                          w="fit-content"
                          isLoading={isSubmitting}
                        >
                          Notify Me
                        </PrimaryButton>
                        {errorMeg && <Text variant="error">{errorMeg}</Text>}
                      </>
                    )}
                  </Stack>
                </Stack>
              </form>
            </FormProvider>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
export default GeographicWaitlistModal;
