import {
  Box,
  BoxProps,
  Stack,
  StackProps,
  useRadio,
  useRadioGroup,
  UseRadioProps,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";

import { ColourAtoms } from "../../../ui/src/DesignSystem";
import { Option } from "./FormRadio";

type RadioCardProps = BoxProps & {
  children: ReactNode | string;
  radio: UseRadioProps;
  inputProps?: BoxProps;
};
function RadioCard({
  radio,
  children,
  inputProps,
  ...containerProps
}: RadioCardProps) {
  const { getInputProps, getCheckboxProps } = useRadio(radio);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        isChecked: radio.isChecked,
      });
    }
    return child;
  });

  return (
    <Box as="label" flex={1} {...containerProps}>
      <input {...input} />
      {typeof children === "string" ? (
        <Box
          {...checkbox}
          cursor="pointer"
          borderRadius="50px"
          color={ColourAtoms.BB.Pink}
          _checked={{ bgColor: ColourAtoms.BB.Pink, color: "white" }}
          height="100%"
          px={5}
          py={1}
          {...inputProps}
        >
          {children}
        </Box>
      ) : (
        childrenWithProps
      )}
    </Box>
  );
}

type RadioCardGroupProps = StackProps & {
  name: string;
  value?: string | number;
  defaultValue?: string | number;
  options: Array<Option>;
  inputProps?: BoxProps;
  containerProps?: BoxProps;
  onValueChange?: (value: string) => void;
};
function RadioCardGroup({
  name,
  value,
  defaultValue,
  onValueChange,
  options,
  inputProps,
  containerProps,
  ...restProps
}: RadioCardGroupProps) {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    value,
    defaultValue,
    onChange: onValueChange,
  });

  const group = getRootProps();

  return (
    <Stack {...group} {...restProps}>
      {options.map(({ value, label }) => {
        const radio = getRadioProps({ value });
        return (
          <RadioCard
            key={value}
            radio={radio}
            inputProps={inputProps}
            {...containerProps}
          >
            {label}
          </RadioCard>
        );
      })}
    </Stack>
  );
}

export default RadioCardGroup;
