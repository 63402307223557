import { JZ } from "@buzzbike/ui/src/DesignSystem";
import { Box, HStack, Stack, Text } from "@chakra-ui/react";
import Image from "next/image";
import { createRef } from "react";

function BikeOptionCard({
  name,
  description,
  image,
  isChecked,
  isWaitlist,
}: {
  name: string;
  description?: string | null;
  image?: string;
  isChecked?: boolean;
  isWaitlist?: boolean;
}) {
  const cardRef = createRef<HTMLDivElement>();

  return (
    <HStack
      height={"112px"}
      borderRadius={16}
      overflow="hidden"
      position="relative"
      boxShadow="2px 2px 20px 2px rgba(0, 0, 0, 0.1)"
      border={`2px solid ${isChecked ? JZ["Pink/2"] : JZ.White}`}
      _hover={{
        cursor: "pointer",
        boxShadow: "2px 2px 20px 2px rgba(0, 0, 0, 0.2)",
      }}
      transition="all 0.2s ease"
      ref={cardRef}
      justifyContent="space-between"
    >
      <Stack
        justifyContent={"space-between"}
        spacing={1}
        flex={1}
        h="100%"
        py={4}
        pl={4}
      >
        <Text
          variant={"secondaryBase"}
          color={"gray.700"}
          position="relative"
          w="fit-content"
        >
          {name}
          {isWaitlist && (
            <Box
              bgColor={JZ["Joyful/1"]}
              px={2}
              py={1}
              rounded={8}
              position="absolute"
              right={"-72px"}
              top={-0.5}
            >
              <Text
                {...JZ["Caption/14 Med"]}
                color="#2D3748"
                textTransform="capitalize"
              >
                Waitlist
              </Text>
            </Box>
          )}
        </Text>
        <Text {...JZ["Caption/14 Med"]} letterSpacing={0.2} color={"#2D3748"}>
          {description}
        </Text>
      </Stack>
      <Box position={"relative"} h="90%" w="50%">
        {image && (
          <Image
            src={image}
            alt={`${name}-image`}
            layout="fill"
            objectFit="contain"
          />
        )}
      </Box>
    </HStack>
  );
}
export default BikeOptionCard;
