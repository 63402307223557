const TickIcon = (props: React.ComponentProps<"svg">) => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" {...props}>
    <path
      opacity="0.4"
      d="M20.4724 49.9699L17 23L42.4646 40.2776L66 33.1137L47.4138 65"
      stroke="currentColor"
      strokeWidth="12"
    />
    <path d="M28 38L35.5 45.5L50 31" stroke="#111827" strokeWidth="3" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57 38.5C57 48.7173 48.7173 57 38.5 57C34.0589 57 29.9834 55.4351 26.7946 52.8267L24.6641 54.9572C28.4034 58.1041 33.2303 60 38.5 60C50.3741 60 60 50.3741 60 38.5C60 26.6259 50.3741 17 38.5 17C26.6259 17 17 26.6259 17 38.5C17 44.0246 19.0837 49.0625 22.508 52.8706L24.6329 50.7458C21.7497 47.4835 20 43.1961 20 38.5C20 28.2827 28.2827 20 38.5 20C48.7173 20 57 28.2827 57 38.5Z"
      fill="#111827"
    />
  </svg>
);
export default TickIcon;
