import { JZ } from "@buzzbike/ui/src/DesignSystem";
import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/react";
import { ReactNode } from "react";
import { FieldError, FieldValues, useController } from "react-hook-form";

import { UseControllerPropsNarrowing } from "utils/formTyping";

type FormSelectProps<TFieldValues extends FieldValues> = Omit<
  CheckboxProps,
  "name" | "defaultValue" | "value" | "isChecked" | "checked"
> & {
  label?: string;
  children?: ReactNode;
  helperText?: string;
} & UseControllerPropsNarrowing<boolean | undefined, TFieldValues>;

const FormCheckbox = <TFieldValues extends FieldValues>(
  props: FormSelectProps<TFieldValues>
) => {
  const {
    name,
    label,
    helperText,
    control,
    defaultValue,
    children,
    ...restProps
  } = props;
  const {
    field,
    fieldState: { error, invalid },
  } = useController({ name, control, defaultValue });

  return (
    <FormControl isInvalid={invalid}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Checkbox
        variant={"big"}
        isChecked={field.value}
        {...field}
        {...restProps}
      >
        {children}
      </Checkbox>
      <FormErrorMessage color={JZ.Negative}>
        {error && (error as FieldError).message}
      </FormErrorMessage>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};
export default FormCheckbox;
