/*
 * React currently throws a warning when using useLayoutEffect on the server.
 * To get around it, we can lazily show component with useLayoutEffect.
 * Ref: https://gist.github.com/gaearon/e7d97cdf38a2907924ea12e4ebdf3c85
 */
import React, { useEffect, useState } from "react";
import ReactInputMask, { Props } from "react-input-mask";

const InputMask = React.forwardRef<ReactInputMask, Props>((props, ref) => {
  const [showChild, setShowChild] = useState(false);

  // Wait until after client-side hydration to show
  useEffect(() => {
    setShowChild(true);
  }, []);

  if (!showChild) {
    // You can show some kind of placeholder UI here
    return null;
  }

  return <ReactInputMask {...props} ref={ref} />;
});

export default InputMask;
